/* Global styles */
@font-face {
  font-family: "Space Mono";
  src: url("./Fonts/SpaceMono-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Fira Sans";
  src: url("./Fonts/FiraSans-Regular.ttf") format("truetype");
}

* {
  box-sizing: border-box;
}

:root {
  --dark: rgba(0, 0, 0, 0.8);
  --darker: #252424;
  --gray: #8d8d8d;
  --pink: #ff00a0;
  --white: rgb(255, 255, 255);
}

body {
  background-color: var(--dark);
  margin: 0;
  font-family: "Space Mono";
  color: var(--white);
}

a {
  text-decoration: none;
  font-size: clamp(1rem, 1.875vw, 1.25rem);
  font-family: "Fira Sans";
}

li {
  font-size: clamp(1rem, 1.875vw, 1.25rem);
}

button {
  background-color: transparent;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  border: 2px solid white;
  margin-bottom: 1em;
  color: white;
}

input,
select {
  width: 100%;
  height: 2.4em;
  font-size: 1em;
}
